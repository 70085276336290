.leaflet-container {
  width: 100%;
  height: 100%;
}

#root {
  display: flex;
  flex-flow: column;
  height: 100%;
}

html, body {
  height: 100%;
}

.App {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.od-controls {
  background-color: white;
  overflow: scroll;
}

.od-footer {
  height: 10em;
  overflow: scroll;
}
